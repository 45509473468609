'use client';

import { useFilter } from '~/utils/useFilter';
import { useTranslations } from 'next-intl';
import { Card } from '~/components/ui/card';
import { Briefcase, Camera, Mic, Lightbulb, Monitor, Settings, Focus, Route } from 'lucide-react';
import { ReactElement } from 'react';

const CategoryCard = ({ title, icon }: { title: string; icon: ReactElement }) => {
  return (
    <Card className="flex items-center shadow-3xl rounded-lg overflow-hidden hover:bg-gray-100 transition-all cursor-pointer p-6">
      {icon}
      <h3 className="text-lg text-gray-800 font-medium leading-tight">{title}</h3>
    </Card>
  );
};

export default function Categories() {
  const [filter, setFilter] = useFilter();
  const t = useTranslations();

  return (
    <div className="grid md:grid-cols-4 gap-4 grid-cols-1 mb-12">
      <div
        onClick={() => {
          setFilter({ categories: ['95582c22-51f4-4078-a055-4ce3689dc0bd'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Komplektid')}
          icon={<Briefcase className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
      <div
        onClick={() => {
          setFilter({ categories: ['2a2fe4d6-4193-4ed1-9b8c-27be30a8b0b9'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Kaamerad')}
          icon={<Camera className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
      <div
        onClick={() => {
          setFilter({ categories: ['13dd7157-8b1a-427e-84e0-8dfaadfcdb63'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Objektiivid')}
          icon={<Focus className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
      <div
        onClick={() => {
          setFilter({ categories: ['e46a5262-915e-487a-8b02-efc83fdee81f'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Helitehnika')}
          icon={<Mic className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
      <div
        onClick={() => {
          setFilter({ categories: ['18b23e25-b601-4f63-9b97-a0ee734242c3'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Valgustus')}
          icon={<Lightbulb className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
      <div
        onClick={() => {
          setFilter({ categories: ['18992ba2-547b-446f-a5e1-0ea2ce15ee60'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Monitorid')}
          icon={<Monitor className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
      <div
        onClick={() => {
          setFilter({ categories: ['ee393a9a-dbab-49ee-882f-e60e9cefc74f'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Stabilisaatorid')}
          icon={<Route className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
      <div
        onClick={() => {
          setFilter({ categories: ['20da3811-4fc8-4846-8e0a-c13ceb4a7a47'] }, '/search');
        }}
      >
        <CategoryCard
          title={t('Lisavarustus')}
          icon={<Settings className="text-mediarent mr-6" size={60} strokeWidth={0.9} />}
        />
      </div>
    </div>
  );
}
